var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0" },
    [
      _c(
        "v-card",
        {
          staticClass:
            "d-flex align-center rounded pa-5 border border-solid clickable",
          staticStyle: { "border-color": "#EDEDED" },
          attrs: { elevation: "0", height: "100" },
          on: { click: _vm.download },
        },
        [
          _c(
            "div",
            {
              staticClass: "mr-4 pa-3 rounded",
              staticStyle: { "background-color": "#F2F2F2" },
            },
            [_c("v-img", { attrs: { src: _vm.icons.notes } })],
            1
          ),
          _c("div", { staticClass: "text-truncate" }, [
            _vm._v(" " + _vm._s(_vm.lecture.title) + " "),
          ]),
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "100vw" },
          model: {
            value: _vm.isFilePreviewerVisible,
            callback: function ($$v) {
              _vm.isFilePreviewerVisible = $$v
            },
            expression: "isFilePreviewerVisible",
          },
        },
        [
          _vm.isFilePreviewerVisible
            ? _c(
                "file-previewer",
                {
                  attrs: {
                    "show-annotation": _vm.showAnnotation,
                    "preview-url": _vm.previewUrl,
                    "download-url": _vm.downloadUrl,
                    "lecture-id": _vm.lecture.object_id,
                    "share-date": _vm.lecture.share_date,
                    title: _vm.lecture.title,
                    "is-lecture": true,
                    "is-video": _vm.lecture.is_video,
                    "is-download": _vm.lecture.is_downloadable,
                  },
                  on: {
                    "update:showAnnotation": function ($event) {
                      _vm.showAnnotation = $event
                    },
                    "update:show-annotation": function ($event) {
                      _vm.showAnnotation = $event
                    },
                    "show-annotation": function ($event) {
                      _vm.showAnnotation = true
                    },
                    "hide-annotation": function ($event) {
                      _vm.showAnnotation = false
                    },
                    cancel: function ($event) {
                      _vm.isFilePreviewerVisible = false
                    },
                  },
                },
                [
                  _c("annotations-component", {
                    attrs: {
                      slot: _vm.activeSlot,
                      "lecture-id": _vm.lecture.object_id,
                    },
                    on: {
                      "hide-annotation": function ($event) {
                        _vm.showAnnotation = false
                      },
                    },
                    slot: _vm.activeSlot,
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }