<template>
  <div v-if="isStudent">
    <div v-if="filteredContents.length" class="grid" :class="[classes.layout, classes.padding]">
      <s-multiplexed-card
        v-for="(content, index) in filteredContents" :key="index"
        :item="isStudent ? content : getContentDetails(content)"
      />
    </div>
    <div v-else class="pa-2 d-flex justify-center">
      <div>
        {{ isNote ? 'No notes available': isTest ? 'No tests available' : 'no lecture available' }}
      </div>
    </div>
  </div>
  <div v-else class="grid" :class="[classes.layout, classes.padding]">
    <t-multiplexed-card
        v-for="(content, index) in contents"
        :key="index"
        :item="isStudent ? content : getContentDetails(content)"
    ></t-multiplexed-card>
  </div>
</template>

<script>
import TMultiplexedCard from "#ecf/course-design/components/CourseOrganization/TMultiplexedCard.vue";
import SMultiplexedCard from "#ecf/course-design/components/CourseOrganization/SMultiplexedCard.vue";
import { CourseDesignUtils } from "#ecf/course-design/mixins";

export default {
  props: ["courseId", "moduleId","isNote","isTest"],
  components: { TMultiplexedCard, SMultiplexedCard },
  mixins: [CourseDesignUtils],
  computed: {
    isStudent() {
      return this.$route.path.includes("student");
    },
    contents() {
      if (!this.isStudent) {
        return this.$store.getters["design/modules"][this.courseId][
          this.moduleId
        ].contents;
      } else {
        let allModuleContents = this.$store.state.studentCourseDesignDashboard
          .moduleContents[this.$ielts_course_id];
        let module = this.$store.state.studentCourseDesignDashboard
          .courseModules[this.$ielts_course_id][this.moduleId];
        let moduleContentIds = [];
        module.contents.forEach(content => {
          moduleContentIds.push(content.id);
        });
        let moduleContents = moduleContentIds.reduce(
          (accumulator, currentValue) => [
            ...accumulator,
            allModuleContents[currentValue]
          ],
          []
        );
        return moduleContents;
      }
    },   
    details() {
      return this.$store.getters["design/find"][this.courseId];
    },
    classes() {
      return {
        layout: `grid-cols-${this.breakPointVal(
          1,
          2,
          3,
          4
        )} grid-gap-${this.breakPointVal(3, 3, 3, 3)}`,
        padding: `px-${this.breakPointVal(
          2,
          3,
          6,
          6
        )} py-${this.breakPointVal(2, 2, 4, 4)}`
      };
    },
    filteredContents() {
      let filteredData =  []
      if(this.isNote) {
        filteredData = this.contents.filter((el)=> el.object_type === 'lecture' )
      }
      else if(this.isTest) {
        filteredData = this.contents.filter((el)=> el.total_points)
      } else {
        filteredData = this.contents
      }
      return filteredData
    },
  },
  methods: {
    breakPointVal(smValue, mdValue, lgValue, xlValue) {
      let s = this.$vuetify.breakpoint.width;
      return s < 1100
        ? smValue
        : s < 1400 
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    },
    getContentDetails(content) {
      return this.details[content.id];
    }
  }
};
</script>
