var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-column",
      attrs: {
        flat: "",
        height: _vm.sizes.card.height,
        width: _vm.sizes.card.width,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "border-0 border-b border-solid border-primary",
          class: _vm.classes.titleBar,
        },
        [_vm._t("title")],
        2
      ),
      _c("div", { class: _vm.classes.selectorArea }, [_vm._t("controls")], 2),
      _c(
        "div",
        {
          staticClass:
            "flex-grow-1 rounded border-2 overflow-y-auto overflow-x-hidden",
          class: _vm.classes.contentArea,
        },
        [_vm._t("contents")],
        2
      ),
      _c(
        "div",
        {
          staticClass: "mb-2 px-3 xl:text-xs lg:text-xs md:text-xs sm:text-xs",
          class: _vm.classes.warningArea,
          staticStyle: { "min-height": "14px" },
        },
        [
          _c("v-slide-y-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errorMessages.length > 0,
                    expression: "errorMessages.length > 0",
                  },
                ],
                staticClass: "decline--text",
              },
              [_vm._v(" " + _vm._s(_vm.errorMessages[0]) + " ")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-end", class: _vm.classes.buttonArea },
        [
          _c(
            "v-btn",
            {
              staticClass:
                "ft-medium xl:text-base lg:text-base md:text-base sm:text-base",
              class: { "d-flex flex-grow-1": _vm.screenWidth < 600 },
              attrs: { depressed: "", outlined: "", large: "" },
              on: {
                click: function ($event) {
                  return _vm.cancel()
                },
              },
            },
            [_vm._v("Cancel ")]
          ),
          _c(
            "v-btn",
            {
              staticClass:
                "primary white--text ml-3 ft-medium xl:text-base lg:text-base md:text-base sm:text-base",
              class: { "d-flex flex-grow-1": _vm.screenWidth < 600 },
              attrs: { depressed: "", large: "", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v("Save ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }