<template>
  <v-card flat outlined class="grey lighten-4">
    <v-row no-gutters class="pa-2">
      <v-col
        order="1"
        :cols="screenWidth < 600 ? 8 : 3"
        class="d-flex align-center justify-start sm:text-sm"
      >
        <div class="text-truncate">{{ title }}</div>
      </v-col>
      <v-col
        :order="screenWidth < 600 ? 3 : 2"
        :cols="screenWidth < 600 ? 8 : 3"
        class="d-flex align-center justify-start sm:text-sm"
      >
        <div class="text-truncate">{{ storesType }}</div>
      </v-col>
      <v-col
        :order="screenWidth < 600 ? 2 : 3"
        :cols="screenWidth < 600 ? 4 : 3"
        :class="screenWidth < 600 ? 'justify-end' : 'justify-center'"
        class="d-flex align-center"
      >
        <v-switch
          v-model="status"
          class="ma-0 pt-0"
          dense
          hide-details
          :class="{ 'pl-3 pr-1': screenWidth < 600 }"
          :color="colors.stateButton"
        ></v-switch>
      </v-col>
      <v-col
        order="4"
        :cols="screenWidth < 600 ? 4 : 3"
        class="d-flex align-center justify-end"
      >
        <v-btn icon @click="remove">
          <v-icon :color="colors.deleteButton">delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { CourseDesignUtils } from "#ecf/course-design/mixins";

export default {
  props: ["item"],
  mixins: [CourseDesignUtils],
  model: { prop: "item", event: "update" },
  computed: {
    status: {
      get() {
        return this.item.isPublic;
      },
      set(value) {
        this.$set(this.item, "isPublic", value);
      }
    },
    title() {
      return this.item.name;
    },
    storesType() {
      return this.item.type || "type";
    },
    colors() {
      return {
        stateButton: this.state === "deleting" ? "white" : "primary",
        deleteButton: this.state === "deleting" ? "white" : "decline"
      };
    },
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(value) {
        this.$emit("update", value);
      }
    }
  },
  methods: {
    async remove() {
      this.updateParent();
    },
    updateParent(){
      this.status = false;
      this.$emit("remove", this.item.id);
    }
  }
};
</script>