var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass:
        "d-flex align-center rounded pa-5 border border-solid clickable",
      staticStyle: { "border-color": "#EDEDED" },
      attrs: { elevation: "0", height: "100" },
      on: { click: _vm.startExam },
    },
    [
      _c(
        "div",
        {
          staticClass: "mr-4 pa-3 rounded",
          staticStyle: { "background-color": "#F2F2F2" },
        },
        [_c("v-img", { attrs: { src: _vm.icons.exams } })],
        1
      ),
      _c("div", { staticClass: "text-truncate" }, [
        _c("div", [
          _vm._v(" " + _vm._s(_vm.exam.exam_title || _vm.exam.title) + " "),
        ]),
        _c(
          "div",
          {
            staticClass: "d-flex mt-1",
            class: _vm.$vuetify.breakpoint.mdAndDown
              ? "flex-column"
              : "align-center",
          },
          [
            _c("div", { staticClass: "mr-8 d-flex align-center" }, [
              _c(
                "div",
                { staticClass: "pr-2" },
                [_c("v-img", { attrs: { src: _vm.icons.points } })],
                1
              ),
              _c("div", { staticClass: "text-sm" }, [
                _vm._v(" " + _vm._s(_vm.exam.total_points) + " "),
              ]),
            ]),
            _c("div", { staticClass: "d-flex align-center" }, [
              _c(
                "div",
                { staticClass: "pr-2" },
                [_c("v-img", { attrs: { src: _vm.icons.duration } })],
                1
              ),
              _c("div", { staticClass: "text-sm" }, [
                _vm._v(
                  " " + _vm._s(_vm.exam.total_duration.toString()) + " minutes "
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }