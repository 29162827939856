<template>
  <v-card
    v-if="!addNewNote"
    outlined
    style="align-content: end; padding: 20px; margin: 0 auto;"
    class="annotation-component"
  >
    <v-card v-if="'annotation' in annotations" flat>
      <span>
        <span style="font-size:28px;" class="mb-10 mt-5" align="left">{{
          !$language ? "Notes" : "নোট"
        }}</span>
        <span
          style="font-size:18px;"
          class="mb-10 mt-5 pr-6 pl-4 grey--text"
          align="left"
          >{{
            annotations.annotation !== null
              ? annotations.annotation.length
              : "0"
          }}
          {{ !$language ? "notes" : "টি নোট" }}</span
        >
      </span>

      <span style="font-size:18px;" class="hidden-sm-and-down" align="right"
        ><v-btn icon class="close-icon" @click="$emit('hide-annotation')"
          ><v-icon>close</v-icon></v-btn
        ></span
      >
    </v-card>
    <ManagedScroller
      v-if="'annotation' in annotations && annotations.annotation !== null"
      ref="managed-scroller"
      :items="annotations.annotation"
      height="88%"
      :buffer="1200"
      :style="{
        'scroll-behavior': 'smooth'
      }"
      style="height: 50vh"
    >
      <template v-slot="{ item, itemIndex, updateShellSize }">
        <ManagedScrollerShell :size="200">
          <div
            v-if="!item"
            style="position: relative; width: 100%; height: 400px;"
          ></div>
          <div v-else>
            <div align="right" class="mt-8 mb-3 pt-3 pr-6">
              {{ formatDateTime(item.annotation_date_time) }}
            </div>
            <div
              outlined
              readonly
              style="color: #3D3D3D; border: 2px solid #E5E5E5; padding: 24px; text-decoration-color: #3D3D3D"
              v-html="item.annotation_text.replace(/(?:\r\n|\r|\n)/g, '<br />')"
            />
            <MountCheck @mounted="updateShellSize" />
          </div>
        </ManagedScrollerShell>
      </template>
    </ManagedScroller>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          small
          dark
          absolute
          right
          bottom
          color="#0099DC"
          style="margin-bottom: 18%; margin-right: 8%;"
          v-bind="attrs"
          v-on="on"
          @click="addNewNote = true"
        >
          <v-icon> mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>{{ !$language ? "Add new note" : "নতুন নোট যোগ করুন" }}</span>
    </v-tooltip>
  </v-card>
  <v-card
    v-else
    outlined
    style="margin: 0 auto; padding: 10px;"
    class="annotation-component"
  >
    <v-row justify="space-between">
      <span class="mb-10 mt-5 pr-6 pl-4">
        <span style="font-size:28px;" align="left">{{
          !$language ? "Notes " : "নোট"
        }}</span>
        <span
          style="font-size:18px;"
          align="left"
          class="mb-10 mt-5 pr-6 pl-4 grey--text"
          >{{
            annotations.annotation !== null
              ? annotations.annotation.length
              : "0"
          }}
          {{ !$language ? "notes" : "টি নোট" }}</span
        >
      </span>
      <span class="hidden-sm-and-down" align="right"
        ><v-btn icon class="close-icon" @click="$emit('hide-annotation')"
          ><v-icon>close</v-icon></v-btn
        ></span
      >
    </v-row>
    <v-row>
      <v-textarea
        v-model="annotationText"
        outlined
        height="28vh"
        class="mt-6 mx-6"
        :placeholder="
          !$language ? 'Enter your note here...' : 'আপনার নোট লিখুন'
        "
      ></v-textarea>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col>
        <v-btn
          rounded
          color="primary"
          :style="
            `width: ${styles.button.width}px; height: ${styles.button.height}px;`
          "
          @click="createAnnotation()"
          >{{ !$language ? "Add" : "যোগ করুন" }}
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          rounded
          color="red"
          outlined
          :style="
            `width: ${styles.button.width}px; height: ${styles.button.height}px;`
          "
          @click="cancelAnnotationCreation()"
          >{{ !$language ? "Cancel" : "বাতিল করুন" }}
        </v-btn>
      </v-col>
      <v-spacer />
    </v-row>
  </v-card>
</template>

<script>
import lectureService from "#ef/lecture/services/LectureService";
import { ManagedScroller, ManagedScrollerShell } from "vue-managed-scroller";

export default {
  name: "AnnotationsComponent",
  components: {
    ManagedScroller,
    ManagedScrollerShell,
    MountCheck: {
      mounted() {
        this.$emit("mounted");
      },
      render: createElement =>
        createElement("span", { style: { display: "none" } })
    }
  },
  props: {
    lectureId: {
      type: String,
      default: "",
      required: true
    }
  },
  data() {
    return {
      annotations: {},
      annotationText: "",
      addNewNote: false
    };
  },
  computed: {
    styles() {
      return {
        button: {
          height: this.$language ? "35" : "30",
          width: this.$language ? "100" : "80"
        }
      };
    }
  },
  async created() {
    await this.getAnnotations();
  },
  methods: {
    async createAnnotation() {
      if (this.annotationText.trim() === "") {
        this.addNewNote = false;
        this.annotationText = "";
        return;
      }
      const params = {
        lecture_id: this.lectureId,
        annotation_text: this.annotationText
      };
      try {
        await lectureService.save_annotation(params);
        this.annotationText = "";
        await this.getAnnotations();
        this.addNewNote = false;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    cancelAnnotationCreation() {
      this.annotationText = "";
      this.addNewNote = false;
    },
    async getAnnotations() {
      const params = {
        lecture_id: this.lectureId
      };
      try {
        this.annotations = await lectureService.get_annotations(params);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    formatDateTime(date) {
      let local = this.$language ? "bn-bd" : "en-gb";

      let dateObject = new Date(date);
      dateObject.setUTCFullYear(dateObject.getFullYear());
      dateObject.setUTCMonth(dateObject.getMonth());
      dateObject.setUTCDate(dateObject.getDate());
      dateObject.setUTCHours(dateObject.getHours());
      dateObject.setUTCSeconds(dateObject.getSeconds());

      return dateObject.toLocaleString(local, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
      });
    }
  }
};
</script>

<style lang="scss">
.annotation-component {
  @media (min-width: 600px) {
    width: 22%;
  }
  @media (max-width: 599px) {
    width: 90vw;
  }
}
</style>
