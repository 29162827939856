<!--In the Back-End Exam, Assignment, Project, lectures are called stores-->
<template>
  <div class="border border-solid border-primary rounded bg-white">
    <div class="pa-1">
      <v-simple-checkbox
        color="primary"
        :value="isSelected"
        @click="changeValue()"
      ></v-simple-checkbox>
    </div>
    <div class="pl-7 pb-5">
      <div
        class="pb-3 pr-7 ft-bold xl:text-base lg:text-base md:text-base sm:text-base text-truncate"
      >
        {{ item.name }}
      </div>
      <div class="ft-light xl:text-sm lg:text-sm md:text-sm sm:text-sm">
        <span class="mr-1">Available Date:</span>{{ item.date }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "selected"],
  model: { prop: "selected", event: "update" },
  data() {
    return {};
  },
  computed: {
    isSelected() {
      return this.selected.includes(this.item.id);
    }
  },
  methods: {
    changeValue() {
      if (this.isSelected) {
        let index = this.selected.indexOf(this.item.id);
        this.selected.splice(index, 1);
      } else {
        this.selected.push(this.item.id);
      }
    }
  }
};
</script>
