<template>
  <v-card 
    class="d-flex align-center rounded pa-5 border border-solid clickable" 
    @click="startExam"
    elevation="0" 
    height="100"
    style="border-color: #EDEDED;"
    >
    <div class="mr-4 pa-3 rounded" style="background-color: #F2F2F2;">
      <v-img :src="icons.exams" />
    </div>
    <div class="text-truncate">
      <div>
        {{ exam.exam_title || exam.title }}
      </div>
      <div class="d-flex  mt-1" :class="$vuetify.breakpoint.mdAndDown ? 'flex-column' : 'align-center'">
          <div class="mr-8 d-flex align-center">
            <div class="pr-2"> 
              <v-img :src="icons.points" />
            </div>
            <div class="text-sm">
              {{ exam.total_points }}
            </div>
          </div>
          <div class="d-flex align-center">
            <div class="pr-2"> 
              <v-img :src="icons.duration" />
            </div>
            <div class="text-sm">
              {{ exam.total_duration.toString() }} minutes
            </div>
          </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import examIcon from "@ef/app-shell/assets/ielts/exams.svg"
import pointIcon from "@ef/app-shell/assets/ielts/points.svg"
import durationIcon from "@ef/app-shell/assets/ielts/duration.svg"

export default {
  name: "MExamListItem",
  props: {
    exam: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loadingExam: false,
      loadingHistory: false
    };
  },
  computed: {
    availableUpTo() {
      if (!this.exam.end_date) return "Always";
      return new Date(this.exam.end_date)
        .toString()
        .split(" ")
        .splice(0, 4)
        .join(" ");
    },
    availableFrom() {
      return new Date(this.exam.start_date)
        .toString()
        .split(" ")
        .splice(0, 4)
        .join(" ");
    },
    icons() {
      return {
        exams: examIcon,
        points: pointIcon,
        duration: durationIcon,
      };
    },
  },

  methods: {
    async startExam() {
      await this.$router.push({
        name: "entry-exam",
        params: { examId: this.exam.exam_id || this.exam.id }
      });
      /*if (this.loadingExam) return;
      try {
        this.loadingExam = true;
        const { student_exam_id: studentExamId } = await this.$store.dispatch(
          "studentExam/start",
          {
            examId: this.exam.exam_id ?? this.exam.id,
            batchId: this.$ielts_batch_id
          }
        );
        await this.$router.push({
          name: "studentsExamRoutes",
          params: { appear: "appear", testId: studentExamId }
        });
      } catch (e) {
        this.$logger.log(e);
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loadingExam = false;
      }*/
    },
    async showHistory() {
      try {
        this.loadingHistory = true;
        await this.$router.push({
          name: "studentsAppearExamRoutes",
          params: {
            appear: "history",
            testId: this.exam.is_attempted ? this.exam.exam_history_id : "N/A"
          }
        });
      } catch (e) {
        this.$logger.log(e);
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loadingHistory = false;
      }
    }
  }
};
</script>
