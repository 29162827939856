export const CourseDesignUtils = {
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    }
  },
  methods: {
    breakPointValues(smValue, mdValue, lgValue, xlValue) {
      let s = this.screenWidth;
      return s < 600
        ? smValue
        : s < 961
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    }
  }
};
