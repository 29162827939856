var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("create-content-card", {
    ref: "contentField",
    attrs: {
      rules: [() => _vm.listNotEmpty(_vm.selected, "Contents")],
      loading: _vm.loading,
    },
    on: { save: _vm.progress, cancel: _vm.exit },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _c(
              "span",
              {
                staticClass:
                  "ft-bold xl:text-2xl lg:text-2xl md:text-2xl sm:text-xl",
              },
              [_vm._v(_vm._s(_vm.edit ? "Edit" : "Create") + " Modules")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "controls",
        fn: function () {
          return [
            _vm.step === 0
              ? _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: _vm.cols.controls } },
                      [
                        _c(
                          "v-form",
                          { ref: "nameField" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                counter: "100",
                                label: "Module name",
                                placeholder: "Enter your module name",
                                dense: _vm.screenWidth < 600,
                                rules: [
                                  _vm.required,
                                  (v) => _vm.minLength(v?.trim(), 5, `Name`),
                                  (v) => _vm.maxLength(v?.trim(), 100, `Name`),
                                ],
                              },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  $event.preventDefault()
                                  return (() => false).apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.title,
                                callback: function ($$v) {
                                  _vm.title =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "title",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-col", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.screenWidth > 960,
                          expression: "screenWidth > 960",
                        },
                      ],
                      attrs: { cols: "4" },
                    }),
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex justify-end align-start",
                        attrs: { cols: _vm.cols.controls },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "blue lighten-5 primary--text",
                            attrs: {
                              depressed: "",
                              large: "",
                              block: _vm.screenWidth < 600,
                            },
                            on: { click: _vm.moveToStepOne },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.icons.selectContent,
                                alt: "icn-cnt-sel",
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "ml-1 ft-light xl:text-base lg:text-base md:text-base sm:text-base",
                              },
                              [_vm._v("Select content")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm.step === 1
              ? _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: _vm.cols.groupItems } },
                      [
                        _c(
                          "v-item-group",
                          {
                            staticClass: "d-flex justify-space-between",
                            attrs: { mandatory: "" },
                            model: {
                              value: _vm.groupItemSelected,
                              callback: function ($$v) {
                                _vm.groupItemSelected = $$v
                              },
                              expression: "groupItemSelected",
                            },
                          },
                          _vm._l(_vm.groupItemData, function (item, i) {
                            return _c("v-item", {
                              key: i,
                              staticClass: "group-item-style",
                              attrs: { value: item },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ toggle, active }) {
                                      return [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "rounded border border-solid border-primary",
                                            class: [
                                              { "item-selected": active },
                                              _vm.classes.groupItems.buttons
                                                .padding,
                                            ],
                                            attrs: { flat: "" },
                                            on: { click: toggle },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: _vm.icons[
                                                      item.toLowerCase()
                                                    ][active],
                                                    alt: `icn-${item.toLowerCase()}`,
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.screenWidth >=
                                                          600,
                                                        expression:
                                                          "screenWidth >= 600",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "md:text-sm sm:text-sm",
                                                    class: [
                                                      {
                                                        "primary--text": active,
                                                      },
                                                      _vm.classes.groupItems
                                                        .buttons.text.margin,
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(item) + " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "contents",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "pa-3" },
              [
                _c(
                  "v-row",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.step === 0,
                        expression: "step === 0",
                      },
                    ],
                    attrs: { "no-gutters": "" },
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-card",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.items.length > 0,
                                expression: "items.length > 0",
                              },
                            ],
                            staticClass: "border-primary",
                            attrs: { flat: "", outlined: "" },
                          },
                          [
                            _c(
                              "v-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.screenWidth >= 600,
                                    expression: "screenWidth >= 600",
                                  },
                                ],
                                staticClass: "pa-2",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-start",
                                    attrs: { cols: "3" },
                                  },
                                  [_vm._v("Name ")]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-start",
                                    attrs: { cols: "3" },
                                  },
                                  [_vm._v("Type ")]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-center",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c("span", [_vm._v("Private")]),
                                    _c("v-switch", {
                                      staticClass: "ma-0 pt-0 pl-3 pr-1",
                                      attrs: {
                                        color: "primary",
                                        dense: "",
                                        "hide-details": "",
                                      },
                                      on: { change: _vm.setPublicStatusAll },
                                      model: {
                                        value: _vm.globalPublicStatus,
                                        callback: function ($$v) {
                                          _vm.globalPublicStatus = $$v
                                        },
                                        expression: "globalPublicStatus",
                                      },
                                    }),
                                    _c("span", [_vm._v("Public")]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.screenWidth < 600,
                                    expression: "screenWidth < 600",
                                  },
                                ],
                                staticClass: "pa-2",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex align-center sm:text-sm",
                                    attrs: { cols: "8" },
                                  },
                                  [_vm._v("Public ")]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-end",
                                    attrs: { cols: "4" },
                                  },
                                  [
                                    _c("v-switch", {
                                      staticClass: "ma-0 pt-0",
                                      attrs: {
                                        color: "primary",
                                        dense: "",
                                        "hide-details": "",
                                      },
                                      on: { change: _vm.setPublicStatusAll },
                                      model: {
                                        value: _vm.globalPublicStatus,
                                        callback: function ($$v) {
                                          _vm.globalPublicStatus = $$v
                                        },
                                        expression: "globalPublicStatus",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.items, function (item, index) {
                      return _c(
                        "v-col",
                        {
                          key: index,
                          staticClass: "mt-3 mr-3",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("module-item-card", {
                            on: { remove: _vm.removeItem },
                            model: {
                              value: _vm.items[index],
                              callback: function ($$v) {
                                _vm.$set(_vm.items, index, $$v)
                              },
                              expression: "items[index]",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "v-row",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.step === 1,
                        expression: "step === 1",
                      },
                    ],
                    attrs: { "no-gutters": "" },
                  },
                  _vm._l(
                    _vm.category[_vm.groupItemSelected],
                    function (item, index) {
                      return _c(
                        "v-col",
                        {
                          key: index,
                          staticClass: "pr-3 pb-3",
                          attrs: { cols: _vm.cols.contentItems },
                        },
                        [
                          _c("stores-item-card", {
                            attrs: { item: item },
                            model: {
                              value: _vm.selected,
                              callback: function ($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected",
                            },
                          }),
                        ],
                        1
                      )
                    }
                  ),
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }