var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "grey lighten-4", attrs: { flat: "", outlined: "" } },
    [
      _c(
        "v-row",
        { staticClass: "pa-2", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-start sm:text-sm",
              attrs: { order: "1", cols: _vm.screenWidth < 600 ? 8 : 3 },
            },
            [
              _c("div", { staticClass: "text-truncate" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-start sm:text-sm",
              attrs: {
                order: _vm.screenWidth < 600 ? 3 : 2,
                cols: _vm.screenWidth < 600 ? 8 : 3,
              },
            },
            [
              _c("div", { staticClass: "text-truncate" }, [
                _vm._v(_vm._s(_vm.storesType)),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              class: _vm.screenWidth < 600 ? "justify-end" : "justify-center",
              attrs: {
                order: _vm.screenWidth < 600 ? 2 : 3,
                cols: _vm.screenWidth < 600 ? 4 : 3,
              },
            },
            [
              _c("v-switch", {
                staticClass: "ma-0 pt-0",
                class: { "pl-3 pr-1": _vm.screenWidth < 600 },
                attrs: {
                  dense: "",
                  "hide-details": "",
                  color: _vm.colors.stateButton,
                },
                model: {
                  value: _vm.status,
                  callback: function ($$v) {
                    _vm.status = $$v
                  },
                  expression: "status",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-end",
              attrs: { order: "4", cols: _vm.screenWidth < 600 ? 4 : 3 },
            },
            [
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.remove } },
                [
                  _c("v-icon", { attrs: { color: _vm.colors.deleteButton } }, [
                    _vm._v("delete"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }