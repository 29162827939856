<template>
  <v-card
    flat
    :height="sizes.card.height"
    :width="sizes.card.width"
    class="d-flex flex-column"
  >
    <div
      :class="classes.titleBar"
      class="border-0 border-b border-solid border-primary"
    >
      <slot name="title"></slot>
    </div>
    <div :class="classes.selectorArea">
      <slot name="controls"></slot>
    </div>
    <div
      :class="classes.contentArea"
      class="flex-grow-1 rounded border-2 overflow-y-auto overflow-x-hidden"
    >
      <slot name="contents"></slot>
    </div>
    <div
      :class="classes.warningArea"
      class="mb-2 px-3 xl:text-xs lg:text-xs md:text-xs sm:text-xs"
      style="min-height: 14px;"
    >
      <v-slide-y-transition>
        <div v-show="errorMessages.length > 0" class="decline--text">
          {{ errorMessages[0] }}
        </div>
      </v-slide-y-transition>
    </div>
    <div :class="classes.buttonArea" class="d-flex justify-end">
      <v-btn
        depressed
        outlined
        large
        class="ft-medium xl:text-base lg:text-base md:text-base sm:text-base"
        :class="{ 'd-flex flex-grow-1': screenWidth < 600 }"
        @click="cancel()"
        >Cancel
      </v-btn>
      <v-btn
        depressed
        large
        class="primary white--text ml-3 ft-medium xl:text-base lg:text-base md:text-base sm:text-base"
        :class="{ 'd-flex flex-grow-1': screenWidth < 600 }"
        :loading="loading"
        @click="save()"
        >Save
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { CourseDesignUtils } from "#ecf/course-design/mixins";

export default {
  props: {
    rules: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  mixins: [CourseDesignUtils],
  data() {
    return {
      errorMessages: []
    };
  },
  computed: {
    classes() {
      return {
        titleBar: `pa-${this.screenWidth < 600 ? 4 : 8}`,
        selectorArea: `pa-${this.screenWidth < 600 ? 4 : 8}`,
        contentArea: `ma-${this.screenWidth < 600 ? 4 : 8} mt-${
          this.screenWidth < 600 ? "0" : "n2"
        } mb-2 ${
          this.errorMessages.length > 0
            ? "border-solid border-decline"
            : "border-dashed border-default"
        }`,
        warningArea: `mx-${this.screenWidth < 600 ? 4 : 8}`,
        messageArea: `px-${this.screenWidth < 600 ? 4 : 8}`,
        buttonArea: `pa-${this.screenWidth < 600 ? 4 : 8} pt-2`
      };
    },
    sizes() {
      return {
        card: {
          height: this.breakPointValues("600px", "720px", "540px", "720px"),
          width: this.breakPointValues("89vw", "540px", "960px", "1280px")
        }
      };
    },
  },
  methods: {
    cancel() {
      if(this.loading) return;
      this.$emit("cancel");
    },
    save() {
      if(this.loading) return;
      this.$emit("save");
    },
    validate() {
      this.reset();
      this.rules.forEach(rule => {
        let outcome = rule();
        if (outcome !== true) this.errorMessages.push(outcome);
      });
      return this.errorMessages.length === 0;
    },
    reset() {
      this.errorMessages.splice(0, this.errorMessages.length);
    }
  }
};
</script>

<style scoped lang="scss"></style>
