var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.addNewNote
    ? _c(
        "v-card",
        {
          staticClass: "annotation-component",
          staticStyle: {
            "align-content": "end",
            padding: "20px",
            margin: "0 auto",
          },
          attrs: { outlined: "" },
        },
        [
          "annotation" in _vm.annotations
            ? _c("v-card", { attrs: { flat: "" } }, [
                _c("span", [
                  _c(
                    "span",
                    {
                      staticClass: "mb-10 mt-5",
                      staticStyle: { "font-size": "28px" },
                      attrs: { align: "left" },
                    },
                    [_vm._v(_vm._s(!_vm.$language ? "Notes" : "নোট"))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "mb-10 mt-5 pr-6 pl-4 grey--text",
                      staticStyle: { "font-size": "18px" },
                      attrs: { align: "left" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.annotations.annotation !== null
                            ? _vm.annotations.annotation.length
                            : "0"
                        ) +
                          " " +
                          _vm._s(!_vm.$language ? "notes" : "টি নোট")
                      ),
                    ]
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "hidden-sm-and-down",
                    staticStyle: { "font-size": "18px" },
                    attrs: { align: "right" },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "close-icon",
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("hide-annotation")
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("close")])],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          "annotation" in _vm.annotations && _vm.annotations.annotation !== null
            ? _c("ManagedScroller", {
                ref: "managed-scroller",
                staticStyle: { height: "50vh" },
                style: {
                  "scroll-behavior": "smooth",
                },
                attrs: {
                  items: _vm.annotations.annotation,
                  height: "88%",
                  buffer: 1200,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ item, itemIndex, updateShellSize }) {
                        return [
                          _c("ManagedScrollerShell", { attrs: { size: 200 } }, [
                            !item
                              ? _c("div", {
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%",
                                    height: "400px",
                                  },
                                })
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mt-8 mb-3 pt-3 pr-6",
                                        attrs: { align: "right" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatDateTime(
                                                item.annotation_date_time
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("div", {
                                      staticStyle: {
                                        color: "#3D3D3D",
                                        border: "2px solid #E5E5E5",
                                        padding: "24px",
                                        "text-decoration-color": "#3D3D3D",
                                      },
                                      attrs: { outlined: "", readonly: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          item.annotation_text.replace(
                                            /(?:\r\n|\r|\n)/g,
                                            "<br />"
                                          )
                                        ),
                                      },
                                    }),
                                    _c("MountCheck", {
                                      on: { mounted: updateShellSize },
                                    }),
                                  ],
                                  1
                                ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  992876662
                ),
              })
            : _vm._e(),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticStyle: {
                                  "margin-bottom": "18%",
                                  "margin-right": "8%",
                                },
                                attrs: {
                                  fab: "",
                                  small: "",
                                  dark: "",
                                  absolute: "",
                                  right: "",
                                  bottom: "",
                                  color: "#0099DC",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.addNewNote = true
                                  },
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v(" mdi-plus")])],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                408869264
              ),
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(!_vm.$language ? "Add new note" : "নতুন নোট যোগ করুন")
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _c(
        "v-card",
        {
          staticClass: "annotation-component",
          staticStyle: { margin: "0 auto", padding: "10px" },
          attrs: { outlined: "" },
        },
        [
          _c("v-row", { attrs: { justify: "space-between" } }, [
            _c("span", { staticClass: "mb-10 mt-5 pr-6 pl-4" }, [
              _c(
                "span",
                {
                  staticStyle: { "font-size": "28px" },
                  attrs: { align: "left" },
                },
                [_vm._v(_vm._s(!_vm.$language ? "Notes " : "নোট"))]
              ),
              _c(
                "span",
                {
                  staticClass: "mb-10 mt-5 pr-6 pl-4 grey--text",
                  staticStyle: { "font-size": "18px" },
                  attrs: { align: "left" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.annotations.annotation !== null
                        ? _vm.annotations.annotation.length
                        : "0"
                    ) +
                      " " +
                      _vm._s(!_vm.$language ? "notes" : "টি নোট")
                  ),
                ]
              ),
            ]),
            _c(
              "span",
              { staticClass: "hidden-sm-and-down", attrs: { align: "right" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "close-icon",
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("hide-annotation")
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("close")])],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "v-row",
            [
              _c("v-textarea", {
                staticClass: "mt-6 mx-6",
                attrs: {
                  outlined: "",
                  height: "28vh",
                  placeholder: !_vm.$language
                    ? "Enter your note here..."
                    : "আপনার নোট লিখুন",
                },
                model: {
                  value: _vm.annotationText,
                  callback: function ($$v) {
                    _vm.annotationText = $$v
                  },
                  expression: "annotationText",
                },
              }),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-spacer"),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      style: `width: ${_vm.styles.button.width}px; height: ${_vm.styles.button.height}px;`,
                      attrs: { rounded: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.createAnnotation()
                        },
                      },
                    },
                    [_vm._v(_vm._s(!_vm.$language ? "Add" : "যোগ করুন") + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      style: `width: ${_vm.styles.button.width}px; height: ${_vm.styles.button.height}px;`,
                      attrs: { rounded: "", color: "red", outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.cancelAnnotationCreation()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(!_vm.$language ? "Cancel" : "বাতিল করুন") + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("v-spacer"),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }