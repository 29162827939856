<template>
  <v-container class="ma-0 pa-0">
    <v-card 
      class="d-flex align-center rounded pa-5 border border-solid clickable" 
      @click="download"
      elevation="0" 
      height="100"
      style="border-color: #EDEDED;"
      >
      <div class="mr-4 pa-3 rounded" style="background-color: #F2F2F2;">
        <v-img :src="icons.notes" />
      </div>
      <div class="text-truncate">
        {{ lecture.title }}
      </div>
    </v-card>
    <v-dialog v-model="isFilePreviewerVisible" width="100vw">
      <file-previewer
        v-if="isFilePreviewerVisible"
        :show-annotation.sync="showAnnotation"
        :preview-url="previewUrl"
        :download-url="downloadUrl"
        :lecture-id="lecture.object_id"
        :share-date="lecture.share_date"
        :title="lecture.title"
        :is-lecture="true"
        :is-video="lecture.is_video"
        :is-download="lecture.is_downloadable"
        @show-annotation="showAnnotation = true"
        @hide-annotation="showAnnotation = false"
        @cancel="isFilePreviewerVisible = false"
      >
        <annotations-component
          :slot="activeSlot"
          :lecture-id="lecture.object_id"
          @hide-annotation="showAnnotation = false"
        ></annotations-component>
      </file-previewer>
    </v-dialog>
  </v-container>
</template>

<script>
import FilePreviewer from "~ef/components/FilePreviewer";
import lectureService from "../services/LectureService";
import AnnotationsComponent from "#ef/lecture/components/AnnotationsComponent";
import noteIcon from "@ef/app-shell/assets/ielts/notes.svg"

export default {
  name: "MLectureListItem",
  components: {
    AnnotationsComponent,
    FilePreviewer,
  },
  props: {
    lecture: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showAnnotation: false,
      previewUrl: undefined,
      downloadUrl: undefined,
      isFilePreviewerVisible: false
    };
  },

  computed: {
    activeSlot() {
      if (this.showAnnotation && !this.mobile && this.lecture.is_video)
        return "lecture_is_video";
      else if (this.showAnnotation && !this.mobile && !this.lecture.is_video)
        return "lecture_is_not_video";
      else if (this.mobile) return "lecture_mobile";
      return "";
    },
    mobile() {
      return this.$vuetify.breakpoint.width <= 600;
    },
    icons() {
      return {
        notes: noteIcon
      };
    },
  },

  methods: {
    async download() {
      try {
        let a = await lectureService.get(
          this.lecture.object_id,
          this.$ielts_batch_id
        );
        this.isFilePreviewerVisible = true;
        this.previewUrl = a.preview_url;
        this.downloadUrl = a.download_url;
      } 
        catch (e) {
          if (e.cause !== undefined) {
            this.$root.$emit("alert-access", {
              message: e.message,
              cause: e.cause,
              url: window.location.href
            });
          } else {
            this.$root.$emit("alert", [undefined, e.message]);
            this.isFilePreviewerVisible = false;
            this.lectureUrl = "unauthorized";
          }
        }
    }
  }
};
</script>

<style lang="scss">
.m-lecture-list-item {
  font-family: Poppins, sans-serif !important;
  color: #3d3d3d !important;

  .v-card__text {
    color: #3d3d3d !important;
  }

  button {
    font-family: Poppins, sans-serif !important;
  }
}
</style>
