<template>
  <component :is="renderItem.name" v-bind="renderItem.data"></component>
</template>

<script>
import MLectureListItem from "#ecf/lecture/components/MLectureListItem.vue";
import MAssignmentListItem from "#ecf/assignment/components/MAssignmentListItem.vue";
import MProjectListItem from "#ecf/project/components/MProjectListItem.vue";
import MExamListItem from "#ecf/exam/components/MExamListItem.vue";

export default {
  components: {
    MAssignmentListItem,
    MLectureListItem,
    MProjectListItem,
    MExamListItem
  },
  props: ["item"],
  computed: {
    contentType() {
      if ("object_type" in this.item) return this.item.object_type;
      else return "exam";
    },
    renderItem() {
      return {
        name: `m-${this.contentType}-list-item`,
        data: {
          [this.contentType]: this.item,
          renderInCourseDesign: true
        }
      };
    }
  }
};
</script>
