var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "border border-solid border-primary rounded bg-white" },
    [
      _c(
        "div",
        { staticClass: "pa-1" },
        [
          _c("v-simple-checkbox", {
            attrs: { color: "primary", value: _vm.isSelected },
            on: {
              click: function ($event) {
                return _vm.changeValue()
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "pl-7 pb-5" }, [
        _c(
          "div",
          {
            staticClass:
              "pb-3 pr-7 ft-bold xl:text-base lg:text-base md:text-base sm:text-base text-truncate",
          },
          [_vm._v(" " + _vm._s(_vm.item.name) + " ")]
        ),
        _c(
          "div",
          {
            staticClass: "ft-light xl:text-sm lg:text-sm md:text-sm sm:text-sm",
          },
          [
            _c("span", { staticClass: "mr-1" }, [_vm._v("Available Date:")]),
            _vm._v(_vm._s(_vm.item.date) + " "),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }