<template>
  <create-content-card
    ref="contentField"
    :rules="[()=>listNotEmpty(selected, 'Contents')]"
    :loading="loading"
    @save="progress"
    @cancel="exit"
  >
    <template #title>
      <span class="ft-bold xl:text-2xl lg:text-2xl md:text-2xl sm:text-xl"
        >{{ edit ? "Edit" : "Create"}} Modules</span
      >
    </template>
    <template #controls>
      <v-row v-if="step === 0" no-gutters>
        <v-col :cols="cols.controls">
          <v-form ref="nameField">
            <v-text-field
              v-model.trim="title"
              outlined
              counter="100"
              label="Module name"
              placeholder="Enter your module name"
              :dense="screenWidth < 600"
              :rules="[
                required,
                v => minLength(v?.trim(), 5, `Name`),
                v => maxLength(v?.trim(), 100, `Name`)
              ]"
              @keydown.enter.prevent="() => false"
            ></v-text-field>
          </v-form>
        </v-col>
        <v-col v-show="screenWidth > 960" cols="4"></v-col>
        <v-col :cols="cols.controls" class="d-flex justify-end align-start">
          <v-btn
            depressed
            large
            class="blue lighten-5 primary--text"
            :block="screenWidth < 600"
            @click="moveToStepOne"
          >
            <img :src="icons.selectContent" alt="icn-cnt-sel" />
            <span
              class="ml-1 ft-light xl:text-base lg:text-base md:text-base sm:text-base"
              >Select content</span
            >
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else-if="step === 1" no-gutters>
        <v-col :cols="cols.groupItems">
          <v-item-group
            mandatory
            class="d-flex justify-space-between"
            v-model="groupItemSelected"
          >
            <v-item
              v-for="(item, i) in groupItemData"
              :key="i"
              :value="item"
              class="group-item-style"
              v-slot="{ toggle, active }"
            >
              <v-card
                flat
                class="rounded border border-solid border-primary"
                :class="[
                  { 'item-selected': active },
                  classes.groupItems.buttons.padding
                ]"
                @click="toggle"
              >
                <div class="d-flex align-center">
                  <img
                    :src="icons[item.toLowerCase()][active]"
                    :alt="`icn-${item.toLowerCase()}`"
                  />
                  <span
                    v-show="screenWidth >= 600"
                    class="md:text-sm sm:text-sm"
                    :class="[
                      { 'primary--text': active },
                      classes.groupItems.buttons.text.margin
                    ]"
                  >
                    {{ item }}
                  </span>
                </div>
              </v-card>
            </v-item>
          </v-item-group>
        </v-col>
      </v-row>
    </template>
    <template #contents>
      <div class="pa-3">
        <v-row v-show="step === 0" no-gutters>
          <v-col cols="12">
            <v-card
              v-show="items.length > 0"
              flat
              outlined
              class="border-primary"
            >
              <v-row v-show="screenWidth >= 600" no-gutters class="pa-2">
                <v-col cols="3" class="d-flex align-center justify-start"
                  >Name
                </v-col>
                <v-col cols="3" class="d-flex align-center justify-start"
                  >Type
                </v-col>
                <v-col cols="3" class="d-flex align-center justify-center">
                  <span>Private</span>
                  <v-switch
                    color="primary"
                    class="ma-0 pt-0 pl-3 pr-1"
                    dense
                    hide-details
                    v-model="globalPublicStatus"
                    @change="setPublicStatusAll"
                  ></v-switch>
                  <span>Public</span>
                </v-col>
              </v-row>
              <v-row v-show="screenWidth < 600" no-gutters class="pa-2">
                <v-col cols="8" class="d-flex align-center sm:text-sm"
                  >Public
                </v-col>
                <v-col cols="4" class="d-flex align-center justify-end">
                  <v-switch
                    color="primary"
                    class="ma-0 pt-0"
                    dense
                    hide-details
                    v-model="globalPublicStatus"
                    @change="setPublicStatusAll"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            class="mt-3 mr-3"
            v-for="(item, index) in items"
            :key="index"
          >
            <module-item-card
              v-model="items[index]"
              @remove="removeItem"
            ></module-item-card>
          </v-col>
        </v-row>
        <v-row v-show="step === 1" no-gutters>
          <v-col
            v-for="(item, index) in category[groupItemSelected]"
            :key="index"
            :cols="cols.contentItems"
            class="pr-3 pb-3"
          >
            <stores-item-card
              v-model="selected"
              :item="item"
            ></stores-item-card>
          </v-col>
        </v-row>
      </div>
    </template>
  </create-content-card>
</template>

<script>
import CreateContentCard from "#ecf/course-design/components/CreateContentCard.vue";
import StoresItemCard from "#ecf/course-design/components/CreateModule/StoresItemCard.vue";
import ModuleItemCard from "#ecf/course-design/components/CreateModule/ModuleItemCard.vue";
import { FieldValidations } from "/global/utils/validations";
import { CourseDesignUtils } from "#ecf/course-design/mixins";
import _ from "lodash";

export default {
  name: "CreateModulePanel",
  props: ["outgoing", "courseId", "loading", "edit"],
  components: { CreateContentCard, StoresItemCard, ModuleItemCard },
  mixins: [FieldValidations, CourseDesignUtils],
  model: { prop: "outgoing", event: "update" },
  created() {
    this.populateContent();
  },
  data() {
    return {
      step: 0, // 0 is for save module state, 1 is for select contents stage
      title: undefined,
      groupItemSelected: undefined,
      groupItemData: ["Lectures", "Assignments", "Projects", "Exams"],
      contents: [],
      selected: [],
      selectBuffer: undefined,
      globalPublicStatus: undefined
    };
  },
  computed: {
    icons() {
      return {
        selectContent: require("#ecf/course-design/assessts/select_content_icon.svg"),
        lectures: {
          true: require("#ecf/course-design/assessts/icon_lecture_selected.svg"),
          false: require("#ecf/course-design/assessts/icon_lecture.svg")
        },
        assignments: {
          true: require("#ecf/course-design/assessts/icon_assignment_selected.svg"),
          false: require("#ecf/course-design/assessts/icon_assignment.svg")
        },
        projects: {
          true: require("#ecf/course-design/assessts/icon_project_selected.svg"),
          false: require("#ecf/course-design/assessts/icon_project.svg")
        },
        exams: {
          true: require("#ecf/course-design/assessts/icon_exam_selected.svg"),
          false: require("#ecf/course-design/assessts/icon_exam.svg")
        }
      };
    },
    classes() {
      return {
        groupItems: {
          buttons: {
            text: {
              margin: this.breakPointValues("", "ml-1", "ml-3", "ml-3")
            },
            padding: this.breakPointValues(
              "py-2 px-4",
              "py-2 px-3",
              "py-2 px-4",
              "py-2 px-4"
            )
          }
        }
      };
    },
    sizes() {
      return {
        icons: {
          lectures: this.breakPointValues("20", "20", "20", "20"),
          assignments: this.breakPointValues("24", "24", "24", "24"),
          projects: this.breakPointValues("24", "24", "24", "24"),
          exams: this.breakPointValues("20", "20", "20", "20")
        }
      };
    },
    cols() {
      return {
        controls: this.breakPointValues("12", "6", "4", "4"),
        groupItems: this.breakPointValues("12", "12", "8", "6"),
        contentItems: this.breakPointValues("12", "6", "4", "3")
      };
    },
    items() {
      if(this.edit){
        let publicStatus = {}
        this.outgoing.contents?.forEach(item=>{
          publicStatus[item.id] = item.isPublic;
        })
        return this.contents.filter(item => this.selected.includes(item.id)).map(item=>{
          item.isPublic = publicStatus[item.id] || false
          return item;
        })
      }
      else return this.contents.filter(item => this.selected.includes(item.id));
    },
    category() {
      let outgoing = {undefined: []}
      this.groupItemData.forEach(label => {
        let key = label.toLowerCase().slice(0, -1);
        outgoing[label] = this.contents.filter(item => {
          return item.type === key;
        });
      });
      return outgoing;
    },
  },
  watch: {
    "selected.length": {
      handler(value) {
        if (value === 0) {
          this.globalPublicStatus = false;
        } else {
          this.$emit("altered");
        }
      }
    },
    loading: {
      handler(curr, prev){
        if(curr===false && prev===true){
          this.$refs.nameField.reset();
          this.$refs.contentField.reset();
          this.clearSelectedArray();
        }
      }
    },
    outgoing: {
      deep: true,
      immediate: true,
      handler(value){
        if(this.edit){
          this.title = value.title;
          value.contents?.forEach(item=>{
            this.selected.push(item.id);
          })
          this.globalPublicStatus = value.contents?.reduce((acc, item)=>(acc = (acc && item.isPublic)), true);
        }
      }
    },
  },
  methods: {
    populateContent() {
      let data = this.$store.getters["design/find"][this.courseId];
      for (let datum in data) {
        this.contents.push({
          id: datum,
          name: data[datum].title,
          isPublic: false,
          type: data[datum].object_type || "exam",
          date: data[datum].upload_date || data[datum].created_at
        });
      }
    },
    progress() {
      if (this.step === 0) {
        let nameOk = this.$refs.nameField.validate();
        let contentOk = this.$refs.contentField.validate();
        if (nameOk && contentOk) this.exportModule();
      } else if (this.step === 1) {
        this.step = 0;
      }
    },
    exit() {
      if (this.step === 0) {
        this.$refs.nameField.reset();
        this.$refs.contentField.reset();
        this.$emit("close");
        this.clearSelectedArray();
      } else if (this.step === 1) {
        this.step = 0;
        this.commitSelection();
      }
    },
    setPublicStatusAll(value) {
      this.items.forEach(item => {
        this.$set(item, "isPublic", value);
      });
    },
    removeItem(itemId) {
      this.selected.splice(
        this.selected.findIndex(x => x === itemId),
        1
      );
    },
    moveToStepOne() {
      this.step = 1;
      this.revertSelection();
      this.$refs.contentField.reset();
    },
    clearSelectedArray() {
      this.selected.splice(0, this.selected.length);
    },
    commitSelection() {
      this.selected = _.cloneDeep(this.selectBuffer);
    },
    revertSelection() {
      this.selectBuffer = _.cloneDeep(this.selected);
    },
    exportModule() {
      this.$emit("update", { title: this.title, contents: this.items });
      this.$emit("save");
    }
  }
};
</script>

<style scoped lang="scss">
.item-selected::before {
  color: $primary;
}

@media only screen and (max-width: 599px) {
  .group-item-style {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    margin: 0 4px;
  }
  .group-item-style:first-child {
    margin-left: 0;
  }
  .group-item-style:last-child {
    margin-right: 0;
  }
}
</style>
