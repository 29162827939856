<template>
	<div>
		<div class="primary--text xl:text-xl lg:text-xl md:text-lg sm:text-base">
			Course Sections
		</div>
		<div class="d-flex flex-grow-1 overflow-x-auto overflow-y-auto pt-6">
			<v-select
				v-if="screenWidth < 600"
				v-model="selected"
				:items="
					sections.map((section, i) => {
						return {
							text: `${section.title} (${section.contents.length})`,
							value: i
						};
					})
				"
				dense
				outlined
			></v-select>
			<v-slide-group v-else v-model="selected" mandatory show-arrows>
				<v-slide-item
					v-for="(section, index) in sections"
					:key="index"
					v-slot="{ active, toggle }"
				>
					<v-btn
						depressed
						class="mr-3 pa-2 d-flex align-center xl:text-sm lg:text-sm md:text-sm sm:text-xs"
						:class="classes.slideItem.outer[active]"
						:input-value="active"
						@click="toggle"
					>
						{{ section.title }}
						<div
							class="ml-2 rounded px-2 pa-1"
							:class="classes.slideItem.inner[active]"
						>
							{{ section.contents.length }}
						</div>
					</v-btn>
				</v-slide-item>
			</v-slide-group>
		</div>
	</div>
</template>
  
<script>
import { CourseDesignUtils } from "#ecf/course-design/mixins";
export default {
  name: "CourseSections",
	mixins: [CourseDesignUtils],
	props: ["sections"],
	components: {
  },
	data() {
		return {
			selected: undefined,
		}
	},
	watch: {
		selected(newVal) {
			if(newVal !== undefined) {
				this.$emit('onSelect',this.selected)
			}
		}
	},
	computed: {
		classes() {
      return {
        container: `pa-${this.breakPointValues(3, 3, 6, 6)}`,
        slideItem: {
          outer: {
            true: "primary white--text",
            false: "bg-white border border-solid border-primary primary--text"
          },
          inner: {
            true: "white primary--text",
            false: "primary white--text"
          }
        }
      };
    }
	},
	methods: {
		
	}
};
</script>