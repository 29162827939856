var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "primary--text xl:text-xl lg:text-xl md:text-lg sm:text-base",
      },
      [_vm._v(" Course Sections ")]
    ),
    _c(
      "div",
      {
        staticClass: "d-flex flex-grow-1 overflow-x-auto overflow-y-auto pt-6",
      },
      [
        _vm.screenWidth < 600
          ? _c("v-select", {
              attrs: {
                items: _vm.sections.map((section, i) => {
                  return {
                    text: `${section.title} (${section.contents.length})`,
                    value: i,
                  }
                }),
                dense: "",
                outlined: "",
              },
              model: {
                value: _vm.selected,
                callback: function ($$v) {
                  _vm.selected = $$v
                },
                expression: "selected",
              },
            })
          : _c(
              "v-slide-group",
              {
                attrs: { mandatory: "", "show-arrows": "" },
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              },
              _vm._l(_vm.sections, function (section, index) {
                return _c("v-slide-item", {
                  key: index,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ active, toggle }) {
                          return [
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "mr-3 pa-2 d-flex align-center xl:text-sm lg:text-sm md:text-sm sm:text-xs",
                                class: _vm.classes.slideItem.outer[active],
                                attrs: { depressed: "", "input-value": active },
                                on: { click: toggle },
                              },
                              [
                                _vm._v(" " + _vm._s(section.title) + " "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ml-2 rounded px-2 pa-1",
                                    class: _vm.classes.slideItem.inner[active],
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(section.contents.length) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }